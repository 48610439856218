@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Roboto', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
html, body {

  overflow-x: hidden; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#gradient-hero {
  background: rgb(0,36,32);
  background: linear-gradient(90deg, rgba(0,36,32,1) 0%, rgba(94,128,228,1) 29%, #8ad7bc 93%);
  filter: blur(100px);
 

}


#title-shadow {
  filter: blur(5px);
}

.navigation-li-border {
  width: 0%;
  height: 2px;
  background-color: #2563eb;
}


@keyframes navigation-border-animated {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

@keyframes navigation-border-animated-exit {
  from {
    width: 100%;
  }

  to {
    width: 0%;
  }
}


#type {
  opacity: 1;
  animation: type-animation 1s infinite;
}
@keyframes type-animation {
  from {
    opacity: 1;

  }
  to {
    opacity: 0;
  }
}

.smaller-cube {
  bottom: 150px;
  animation: smaller-cube-animation 7s ease-in-out infinite;
}

.bigger-cube {
  bottom: 5px;
  animation: bigger-cube-animation 10s ease-in-out infinite;
}

@keyframes smaller-cube-animation {
  0% { 
    bottom: 150px;

  }
  50% {
    bottom: 120px;
  }

  100% {
    bottom: 150px;
  }
}

@keyframes bigger-cube-animation {
  0% { 
    bottom: 5px;

  }
  50% {
    bottom: -15px;
  }

  100% {
    bottom: 5px;
  }
}


@keyframes navigation-enter {
  from {
    background-color: transparent;
  }

  to {
    background-color: white;
  }
}


@keyframes navigation-exit {
  
}


#rocket {
  animation: rocket;
}

@keyframes rocket-animation {
  from {
    left: 0;
    bottom: 0;
  }
  tp {
    top: 0;
    right: 0;
  }

}

.text-gradient {
    background: linear-gradient(90deg, rgba(0,36,32,1) 0%, rgba(94,128,228,1) 29%, #8ad7bc 93%);
    background-size: 200% auto;
    color: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s linear infinite;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}



.services-blur {
  filter: blur(10px);
}

.arrow-fade-in {
  opacity: 0;
  display: flex;
  animation: arrow-fade 0.5s ease-in-out forwards;
}

.arrow-fade-out {
  opacity: 1;
  display: flex;
  animation: arrow-fade-out 0.5s ease-in-out forwards;
}


@keyframes arrow-fade-out {
  to {
    opacity: 0;
    display: none;
  }
}

@keyframes arrow-fade {
  to {
    opacity: 1;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;

}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8ad7bb;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1976d2;
}

/* 
* Prevents issues when the parent creates a 
* stacking context. (For example, using the transform
* property )
*/

      

